/* The landing specific sass file for the project */
@charset 'utf-8';

// Layout hold all styles for page layouts, such as header, footer, navigation etc.
@import 'layout/navigation';
@import 'layout/header';
@import 'layout/footer';
@import 'layout/sidebar';
@import 'layout/forms';

// Components hold styles for things such as buttons, sliders and other interactable things
@import 'components/buttons';
@import 'components/loaders';

// Page specific styles for the project
@import 'pages/landing';