/* Loading Animation Stuff */

/* Panel Loader ------------------------------------------------------------ */
.panel-loader-container {
    width: 400px;
    height: 400px;
    border-radius: 24px;

    background-color: white;
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.75);

    overflow: hidden;
    opacity: 0;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    animation: 0.5s animation-fadeIn forwards;

    text-align: center;
}

.panel-loader-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
}

/* Full Screen Page Loader ------------------------------------------------- */
.loader-container {
    width: 100%;
    height: 100%;
    z-index: 10000;
    top: 0px;
    opacity: 0;
    position: absolute;
    background-color: lightskyblue;
    align-items: center;
    animation: 1s loader-transition-in forwards;
}

.loader-body {
    position: absolute;
    color: white;
    font-family: sans-serif;
    font-size: 72px;
    text-align: center;
    z-index: 10200;
    top: 50%;
    left: 50%;
    opacity: 0;
    animation: 0.5s loader-body-transition-in 0.75s forwards;
}

.loader-body > h1 {
    margin-bottom: 0px;
    font-size: 144px;
    font-weight: bold;
}

.loader-body > p {
    font-size: 42px;
}

/* Spinner Loader ---------------------------------------------------------- */

.loader-spinner-container {
    width: 768px;
    height: 768px;
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    animation: 0.5s loader-spinner-transition-in 1s forwards;
    box-sizing: unset !important;
}

.loader-spinner {
    width: 640px;
    height: 640px;
    border: 64px solid white;
    border-radius: 50%;
    border-top: 64px solid lightskyblue;
    border-bottom: 64px solid lightskyblue;
    animation: animation-spinner 1s ease-in-out infinite;
    z-index: 10100;
}

/* Progress Bars ----------------------------------------------------------- */

progress {
    /* Disable the default stying for the progress bar */
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;

    /* Get rid of default border in Firefox and Opera (older version) Basically non web-kit or chromium browsers */
    border: none;

    /* Need to be in here for Safari Polyfill so background images can work as expected */
    background-size: auto;

    /* Dimensions */
    width: 100%;
    height: 48px;
    border-radius: 12px;

    margin-bottom: 24px;
}

/* Indeterminate Progress Bar */
progress:not([value]) {
    border-radius: 12px;
}

progress[value] {
    width: 100%;
    height: 48px;
}

progress[value]::-webkit-progress-bar {
    background-color: lightgrey;
    border-radius: 12px;
}

progress[value].progress-indeterminate.progress-small::-webkit-progress-value {
    background-image: -webkit-linear-gradient(-45deg, transparent 33%, rgba(255, 255, 255, .5) 33%, rgba(255, 255, 255, .5) 66%, transparent 66%);
    background-color: lightskyblue;
    border-radius: 12px;
    background-size: 128px 64px;

    -webkit-animation: indeterminate-progress-animation 0.5s linear infinite forwards;
    animation: indeterminate-progress-animation 0.5s linear infinite forwards;
}

progress strong {
    display: none;
}

.progress-small {
    margin-top: 24px;
    width: 90% !important;
    height: 24px !important;
}

/* Animations -------------------------------------------------------------- */

@keyframes animation-fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes animation-spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes animation-spinner {
    0% {
        transform: rotate(0deg) scale(1.0);
    }

    50% {
        transform: rotate(180deg) scale(1.2);
    }

    100% {
        transform: rotate(360deg) scale(1.0);
    }
}

@keyframes loader-transition-in {
    from {
        transform: translate(-400%);
    }

    to {
        transform: translate(0%);
        opacity: 1;
    }
}

@keyframes loader-body-transition-in {
    from {
        transform: translate(-100%, -50%);
        opacity: 0;
    }

    to {
        transform: translate(-50%, -50%);
        opacity: 1;
    }
}

@keyframes loader-spinner-transition-in {
    from {
        opacity: 0;
        transform: scale(0.5) translate(-75%, -75%);
    }

    to {
        opacity: 1;
        transform: scale(1.0) translate(-50%, -50%);
    }
}

@keyframes indeterminate-progress-animation {
    100% {
        background-position: 128px 0px;
    }
}

@-webkit-keyframes indeterminate-progress-animation {
    100% {
        background-position: 128px 0px;
    }
}