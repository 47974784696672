/* Colours ----------------------------------------------------------------- */

$glass-color: rgba(255, 255, 255, 0.7);
$glass-color-active: rgba(0, 0, 0, 0.4); 
$glass-color-hover: rgba(0, 0, 0, 0.3);
$glass-backdrop: blur(4px);

$overlay-color: rgba(255,255,255,0.75);
$overlay-color-dark: rgba(0,0,0,0.75);

/* Timings ----------------------------------------------------------------- */

$default-transition: 0.25s;
$hover-transition: 0.75s;
$active-transition: 0.15s;

/* Padding ----------------------------------------------------------------- */

$half-padding: 12px;
$normal-padding: 24px;