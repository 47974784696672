/* The primary sass file for the project */
@charset "utf-8";

// Abstracts hold sass tools, helper files, variabkles, functions and mixins
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

// Vendor extensions contain overrides for imported styles and frameworks
@import 'vendors-extensions/bulma';
@import 'vendors-extensions/bulma-tooltip';

// These are base styles that we have added from other frameworks
@import '~normalize.css';
@import '~bulma/bulma';
@import '@creativebulma/bulma-tooltip';
@import '~@fortawesome/fontawesome-free';

// Base holds boilerplate code for the project, including standard styles and type
@import 'base/typography';